/* ~~~~~~~~~~~~~~~~~~~~~~~~~ 
Theme variables
~~~~~~~~~~~~~~~~~~~~~~~~~ */
  $font--primary:	'PlantinRoman', Times New Roman, Times, sans-serif;
  $font--primary--italic:	'PlantinItalic', Times New Roman, Times, sans-serif;
  $font--primary--condensed:	'PlantinCondensedBold', Times New Roman, Times, sans-serif;
  
  $font-light:		300;
  $font-regular:	400;
  
  
  $color--dark:		#20313d;
  $color--light:	#fff;
  $color--primary:	hsla(212, 29%, 40%, 1);
  $color--secondary:	hsla(50, 100%, 94%, 1);
    
  $breakpoints: (
   'medium':		(min-width: 800px),
   'large':			(min-width: 1000px),
   'huge':			(min-width: 1200px),
  );

  $pageWidth:		rem(1200);


@keyframes fade {
	0% {
		opacity:1;
	}
	50% {
		opacity:0;
	}
	100% {
		opacity:1;
	}
}