/* ----------------------------------------------------------------------------
 * Mixins
 * ------------------------------------------------------------------------- */
 /* ~~~~~~~~~~~~~~~~~~~~~~~~~ 
 Breakpoints
 ~~~~~~~~~~~~~~~~~~~~~~~~~ */
 $breakpoints: (
   'small':           (min-width: 600px),
   'medium':          (min-width: 800px),
   'large':           (min-width: 1000px),
   'huge':            (min-width: 1600px),
  );
  
  
 /// @access public
 /// @param {String} $breakpoint - Breakpoint
 /// @requires $breakpoints
 @use 'sass:math';
 @mixin respond-to($breakpoint) {
   $raw-query: map-get($breakpoints, $breakpoint);
   
   @if $raw-query {
     $query: if(
     type-of($raw-query) == 'string',
     unquote($raw-query),
     inspect($raw-query)
     );
   
     @media #{$query} {
     @content;
     }
   } @else {
     @error 'No value found for `#{$breakpoint}`. '
      + 'Please make sure it is defined in `$breakpoints` map.';
   }
   }



  $global-font-size: 100%;
  $global-font-size-px: 16px;
  
  // =Font conversion px to rem
  // ------------------------------------------------------
  
  @function stripUnit($number) {
      @return math.div($number , $number * 0 + 1);
  }
  @function unitlessZero($number) {
      $value: $number;
  
      @if (type-of($value) == number) and stripUnit($value) == 0 {
          $value: stripUnit($value);
      }
  
      @return $value;
  }
  
  @function rootFontSize($html-font-size) {
      @return math.div(stripUnit($html-font-size) * 16, 100);
  }
  
  // =px to rem
  // ------------------------------------------------------
  @function rem($val) {
      @return math.div(stripUnit($val), rootFontSize($global-font-size)) + 0rem;
  }
