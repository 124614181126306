.mainPage {
	margin: 0 auto;
}
.layout--grid {
  .layout {
    display: grid;
  }
}


.grid {
		display: grid;
		grid-template-columns: 1fr;		
		gap: 0.25rem;
		grid-template-rows: auto;
		list-style: none;
		padding: 0;
		margin: 0;
		&.middle {
			align-items: center;
		}
		@include respond-to('medium') {
			gap: 0.5rem;
			grid-template-columns: repeat(12,1fr);		
		}
		.grid-item {
			@include respond-to('medium') {
				&.c1 { grid-column-end: span 1}
				&.c2 { grid-column-end: span 2}
				&.c3 { grid-column-end: span 3}
				&.c4 { grid-column-end: span 4}
				&.c5 { grid-column-end: span 5}
				&.c6 { grid-column-end: span 6}
				&.c7 { grid-column-end: span 7}
				&.c8 { grid-column-end: span 8}
				&.c9 { grid-column-end: span 9}
				&.c10 { grid-column-end: span 10}
				&.c11 { grid-column-end: span 11}
				&.c12 { grid-column-end: span 12}
				&.skip-1 { grid-column-start: 2; }
				&.skip-2 { grid-column-start: 3; }
				&.skip-3 { grid-column-start: 4; }
				&.r1 { grid-row-start: 1; }
				&.r2 { grid-row-start: 2; }
				&.r3 { grid-row-start: 3; }
				&.r4 { grid-row-start: 4; }
				&.r5 { grid-row-start: 5; }
				&.r6 { grid-row-start: 6; }
				&.r7 { grid-row-start: 7; }
				&.r8 { grid-row-start: 8; }
				&.r9 { grid-row-start: 9; }
				&.r10 { grid-row-start: 10; }
				&.r11 { grid-row-start: 11; }
				&.r12 { grid-row-start: 12; }
				&.rs2 { grid-row-end: span 2; }
				&.rs3 { grid-row-end: span 3; }
			}
		}
	}