/* ~~~~~~~~~~~~~~~~~~~~~
Theme typography
~~~~~~~~~~~~~~~~~~~~~ */
h1,
.h1 {
	font-family: $font--primary--condensed;
	font-size: rem(30);
	font-weight: $font-light;
	line-height: 1.2;
	margin: 0;
	padding: 0;
	@include respond-to('medium') {
		font-size: rem(36);
	}
}

h2 {
	font-size: rem(24);
	font-weight:  $font-light;
	line-height: 1.4;
	margin: 2rem 0 1.2rem 0;
	padding: 0;
	@include respond-to('medium') {
		font-size: rem(36);
	}
}

h3 {
	font-size: rem(14);
	line-height: 1.4;
	letter-spacing: 1px;
	margin: 0 0 1rem 0;
	padding: 0;
	text-transform: uppercase;
	font-weight: 300;
}

p {
	font-size: rem(18);
	// font-size: clamp(13px, 4vw, 16px);
	line-height: 1.5;
	margin: 0.25rem 0 1.25rem 0;
	padding: 0;
	
	a {
		color: $color--primary;
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
	ul {
		padding: 0 0 0 1rem;
		li {
			margin: 0 0 1.25rem 0;
		}
		a {
			color: $color--primary;
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
			
		}
	}
}


ul {
	a {
		color: $color--primary;
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
		
	}
}
address { margin: 0 0 1.5rem; }

b,
strong {
	font-weight: 500;
}

small {
	font-size: rem(11);
	line-height: 1.2;
}

em {
	font-style: italic;
	font-weight: 300;
}

.center-md {
	@include respond-to('medium') {
		text-align: center;
	}
}

.center-sm {
	text-align: center;
}