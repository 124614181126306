.siteHeader {
	background-color: $color--secondary;
	margin: 0;
	padding: 1rem;
	display: grid;
	grid-template-columns: auto 1fr;
	z-index: 1;
	@include respond-to('medium') {
		grid-template-columns: 1fr auto 1fr;
		padding: 1.25rem 3rem;
	}
}

.brandLogo-icon {
	padding: 0;
	width: rem(40);
	text-align: center;
	display: inline-block;
	@include respond-to('medium') {
	}
}
.brandLogo__link {
	display: inline-block;
	padding: 0;
	text-align: center;
}
.brandLogo {
	font-family: $font--primary--condensed;
	font-size: rem(60);
	font-style: normal;
	font-weight: 400;
	display: block;
	@include respond-to('medium') {
		font-size: rem(100);
	}
}

.actions {	
	display: flex;
	flex-flow: row nowrap;
	margin-left: auto;
	align-items: center;
	@include respond-to('medium') {
		position: relative;
	}
	.langSelector {
		position: relative;
		padding: 0;	
		&-trigger {
			background-color: transparent;
			border: none;
			font-family: $font--primary;
			color: $color--primary;
			cursor: pointer;
		}
		&-list {
			list-style: none;
			margin: 0 1rem;
			padding: 0;
			text-align: center;
			display: flex;
			flex-flow: row wrap;
		}
		&-item {
			&::after {
				content: "/";
				display: inline-block;
				margin: 0 0.15rem;
			}
			&:last-child {
				&::after {
					content: "";
				}
			}
		}
		&-link {
			color: $color--dark;
			display: inline-block;
			font-size: rem(13);
			padding:0;
			text-transform: uppercase;
			text-decoration: none;
			&:visited {
				color: $color--dark;
			}
			&:hover {
				color: $color--primary;
			}
			// &:active {
			// 	display: none;
			// }
		}
		@include respond-to('medium') {
			position: relative;
		}
	}
}