/* ~~~~~~~~~~~~~~~~~~~~~~~~~ 
Page general
~~~~~~~~~~~~~~~~~~~~~~~~~ */    

.singlePage {
    max-width: $pageWidth;
    margin: 0 auto;
    padding: 0;
    &__content {
        max-width: rem(700);
    }
	h1 {
		text-align: center;
	}
}

.apartment-album {
	margin: 0 2rem;
}
/* ~~~~~~~~~~~~~~~~~~~~~~~~~ 
	Page Info
	~~~~~~~~~~~~~~~~~~~~~~~~~ */ 
.pageInfo {
	max-width: rem(768);
	margin: 0 auto;
	padding: 0 1rem;
	
	&-grid {
		
	}
	&-item {
		margin-bottom: 2rem;
	}
	&-link {
		color: $color--primary;
		display: flex;
		flex-flow: column wrap;
		font-size: rem(28);
		font-weight: $font-light;
		.label {
			font-size: rem(20);
			margin: 0.5rem 0 0 0;
		}
		@include respond-to('medium') {
			text-align: center;		
		}
	}
}

.infoSingle-article {
	max-width: rem(768);
	margin: 0 auto;
	padding: 0 1rem;
}

.info {
	ul {
		padding: 0;
		margin: 0;
		line-height: 1.4;
		li {
			margin-bottom: 0.25rem;
		}
	}
}