
/*  ==========================================================================
    Page home
    ========================================================================== */
    .hero {
		margin: 0;
  		overflow: hidden;
  		position: relative;
  		display: flex;
  		flex-flow: row nowrap;
		height: auto;
		min-height: calc(100vh - 4rem);
		  object-fit: cover;
  		@include respond-to('medium') {
  			align-content: flex-end;
			  min-height: unset;
			  max-height: calc(100vh - 8rem);
  		}
	  	&-content {
		  	display: flex;
		  	place-content: center;
		  	flex-flow: row wrap;
		  	position: absolute;
		  	z-index: 0;
		  	top: 0;
		  	bottom: 0;
		  	left: 0;
		  	right: 0;
		  	color: $color--secondary;
		  	text-align: center;
	  	}
	  	&-img {		  
		  	z-index: -1;
		  	max-width: none;
		  	position: absolute;
		  	align-self: flex-start;
			  max-width: rem(1200);
		  	@include respond-to('medium') {
			  max-width: unset;
			  position: relative;
		  		align-self: flex-end;
		  		width: 100%;
  			}
	  	}
    }
	
	.home-block {
		header {
			max-width: rem(580);
			margin: 0 auto;
			padding: 0 1rem;
			@include respond-to('medium') {
				text-align: center;
			}
			h1 {
				margin-bottom: 2rem;
			}
		}
	}
	
	
		
	.img-1 {
		max-height: rem(500);
		animation-name: fade;
		animation-timing-function: ease-in-out;
		animation-iteration-count: infinite;
		animation-duration: 4s;
		animation-direction: alternate;
		position:absolute;
		  left:0;
		  top: 0;
		  transition: opacity 5s ease;
		}
	.img-2 {
		max-height: rem(500);
	}
	.map {
		text-align: center;
	}
	.apartment {
		max-width: $pageWidth;
		margin: 0 auto;
		padding: 0 0.25rem;
	}
	.booking {
		background-color: $color--light;
		text-align: center;
		border: 1px solid $color--primary;
		margin: 0 1rem;
		h2 {
			border-bottom: 1px solid $color--primary;
			margin: 0 0 2rem 0;
			padding: 1rem 0;
		}
	}
		
$animationSpeed: 45s;

@keyframes scroll {
	0% { transform: translateX(0); }
	50% { transform: translateX(calc(-50%))}
	100% { transform: translateX(0)}
}

.articleAsteasu {
	header {
		margin: 4rem auto;
	}
	.grid {
		margin: 0 auto;
		margin-top: 1rem;
		gap: 0;
	}
	h2 {
		text-align: left;
	}
	p {
		max-width: rem(520);
	}
	
	.map {
		display: flex;
		place-content: center;
		.map-mask {
			background-color: $color--light;
			overflow: hidden;
			margin: 0;
			width: 100%;
			min-height: rem(300);
			@include respond-to('medium') {
				overflow: hidden;
			}
		}
		iframe {
			height: 100%;
			width: 100%;
		}
	}
	.asteasu-services {
		padding: 0 1rem;
		@include respond-to('medium') {
			padding: 0 4rem 4rem 6rem;
		}
	}
}

.articleApartment {
	margin-top: 0;
	background-color: $color--dark;
	color: $color--light;
	padding: 0;
	header {
		max-width: $pageWidth;
		margin: 0 auto;
		padding: 2rem;
	}
	.grid {
		margin: 0 auto;
		gap: 2rem;
		background-color: $color--light;
	}
	p {
		max-width: rem(680);
		margin-left: auto;
		margin-right: auto;
	}
	.apartment-featured {
		gap: 2rem 2rem;
		margin: 2rem;

		.grid-item {
			padding: 0;
			color: $color--dark;
			p {
				margin: 0;
			}
			&:first-child {
				border: none;
			}		
		}
	}
	.listServices {
		font-size: rem(13);
		text-align: center;
		background-color: $color--dark;
		padding: 2rem 0;
	}
}


.articleServices {
	background-color: $color--dark;
	.grid {
		padding: 2rem;
		gap: 2rem;
		font-size: rem(13);
		color: $color--primary;
		text-align: center;
	}
}
.articleLeisure {
	padding: 4rem 0;
	.grid {
		max-width: rem(1200);
		margin: 0 auto;	
		padding: 2rem;
		gap: 3rem;	
	}
}
.slider {
	width: 100vw;
	margin: 0;
	.sliderMask {
		min-height: rem(200);
		overflow: hidden;
		position: relative;
		.sliderContent {
			animation: scroll 60s linear infinite;
			display: flex;
			flex-flow: row nowrap;
			align-items: stretch;
			width: calc(600px * 4);
			&.large {
				width: calc(600px * 10);
			}
			.sliderItem {
				margin: 0 rem(1);
				img {
					height: 100%;
				}
			}
		}
	}
}

.articleBooking {
	background-color: $color--light;
	padding: 2rem 1rem;
	.booking-info {
		background-color: lighten($color--dark,80%);
		padding: 1rem;
		border: 1px solid $color--primary;
	}
	.seasons-info {
		margin: 1rem 0;
	}
	.prices,
	.periods {
		list-style: none;
		margin: 0;
		padding: 0;
		text-align: center;
		li {
			margin-bottom: 0.5rem;
		}
	}
	h3 {
		text-align: center;
	}
	
	.field-wrapper {
		display: flex;
		flex-flow: column wrap;
	}
	.select {
		margin-bottom: 1rem;
		select {
			display: inline-block;
			width: auto;
		}
	}
	.privacy {
		margin: 1rem 0;
		font-size: rem(13);
		input[type="checkbox"] {
			margin-right: 0.5rem;
		}
	}
	form {
		display: flex;
		flex-flow: column wrap;
		max-width: rem(500);
		margin: 2rem auto;
		
		label {
			margin-bottom: 0.25rem;
			font-size: rem(14);
		}
		input {
			padding: 0.5rem;
			margin-bottom: 1rem;
			border: 1px solid $color--primary;
		}
		textarea {
			border: 1px solid $color--primary;
		}
		.select {
			display: flex;
			flex-flow: row wrap;
			align-items: center;
			justify-content: space-between;
			label {
				margin: 0 1rem 0 0;
				
			}
		}
		select {
			flex: 0 1 rem(52);
		}
		.btn-main {
			background-color: $color--primary;
			color: $color--light;
			border: none;
			max-width: rem(200);
			margin: 2rem auto;
			padding: 1rem;
		}
	}
}


.icon-tagline {
	display: flex;
	gap: 8px;
	padding: 1rem 0;
	justify-content: center;	
}
.section-booking {
	display: grid;
	grid-template-columns: 1fr;
	background-color: rgba($color--primary,0.15);
	border-radius: rem(5);
	@include respond-to('medium') {
		grid-template-columns: 1fr 2fr;
	}
}
.chekin {
	background-color: rgba($color--primary,0.1);
	padding: rem(16) rem(24);
	display: flex;
	flex-flow: column wrap;
	gap: 1rem;
	p {
		margin: 0;
		padding: 0;
		font-size: rem(16);
		color: $color--primary;
		a {
			font-family: $font--primary--italic;
			font-style: italic;
		}
	}
}