/* ==========================================================================
   Footer
   ========================================================================== */
	 .siteFooter {
		 margin: 6rem auto 10rem auto;
		 width: 100%;
		 max-width: $pageWidth;
		 padding: 0 1rem;

		 display: grid;
		 grid-template-columns: 1fr;
		 text-align: center;
		 @include respond-to("medium") {
			grid-template-columns: 1fr 1fr 1fr;
			margin: 0 auto;
			padding: 4rem 0;
		  }
		p {
			font-size: rem(13);
			a {
				&:hover {
					text-decoration: underline ;	
				}
			}
		}
		.footer-contact {
			text-align: center;
		}
		.footerNav {
			display: flex;
			flex-flow: wrap row;
			justify-content: center;
			font-size: rem(13);
			list-style: none;

			padding: 0;
			margin: 0;
			a {
				padding: 0 1rem 0 0;
				display: inline-block;
				&:hover {
					text-decoration: underline ;	
				}
			}
			@include respond-to("medium") {
				flex-flow: row nowrap;

			  }
		}
	 }
