/* ~~~~~~~~~~~~~~~~~~~~~~~~~ 
Main nav
~~~~~~~~~~~~~~~~~~~~~~~~~ */
.globalNav {
	display: none;
	@include respond-to("medium") {
		display: block;
	}
	&__menu {
		list-style: none;
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		padding: 0;
		margin: 0;
		width: 100%;
		height: 100%;
		li {
			margin: 0 2rem 0 0;
			z-index: 0;
			position: relative;

			@include respond-to("medium") {
			}
		}
	}
	&__link {
		color: $color--dark;
		line-height: 1;
		text-decoration: none;
		text-align: center;
		
		font-size: rem(16);
		
		display: block;
		padding: 0.5rem 0;
		transition: all .4s ease;
		
		&:hover {
			text-decoration: none;
		}
		&:focus {
			outline: 1px dotted $color--dark;
		}
		&--active {
			@extend .globalNav__link;
		}
	
	}
}


.navSection {
	padding: 0;
	margin: 1rem 0 0 0;
	border: 1px solid $color--primary;
	grid-row: 2 / 3;
	grid-column: 1 / 3;
	@include respond-to('medium') {
		grid-column: 1/2;
		grid-row: 1;
		margin: 0 4rem 0 0;
		border: none;
	}
	&-list {
		list-style: none;
		display: flex;
		flex-flow: row nowrap;
		text-align: center;
		justify-content: space-evenly;
		align-items: stretch;
		align-content: stretch;
		padding: 0;
		margin: 0;
		@include respond-to('medium') {
			border: none;
		}
		li {
			border-left: 1px solid $color--primary;
			border-right: 1px solid $color--primary;
			display: flex;
			flex-flow: column wrap;
			place-content: center;
			&:last-child,
			&:first-child {
				border:none;	
			}
			@include respond-to('medium') {
				border: none;
			}
		}
		a {
			display: inline-block;
			padding: 1rem 0.5rem;
			font-size: rem(12);
			text-transform: uppercase;
			text-decoration: none;	
		}
	}
}

