/*  ==========================================================================
    General
    ========================================================================== */
    *, *:before, *:after {
      box-sizing: inherit;
    }

    html {
      height: 100%;
      box-sizing: border-box;
	  scroll-behavior: smooth;

    }
    body {
      background: $color--secondary;
      color: $color--dark;
      font-family: $font--primary;
	  	font-weight: $font-light;
      min-height: 100%;
      margin: 0;
      padding: 0;
      text-rendering: optimizeLegibility;
    }

    .pageLayout {
    }

/*  ==========================================================================
    Images
    ========================================================================== */

    img {
      display: block;
      height: auto;
      max-width: 100%;
    }

    figure {
      margin: 0;
    }

    a {
      color: $color--primary;
      text-decoration: none;
      &:visited {
       color: $color--dark;
      }
      &:hover,
      &:active {
		color: $color--primary;
      }
    }
	
	.btn-main {
		background-color: $color--secondary;
		border: 1px solid $color--dark;
		color: $color--dark;
		display: inline-block;
		font-size: rem(15);
		margin: 0;
		padding: 0.5rem 1rem 0.4rem 1rem;
		text-decoration: none;
		border-radius: 3px;
		text-transform: uppercase;
		letter-spacing: 1px;
		line-height: 1;
		&:visited {
			color: $color--dark;
		}
		&:hover {
			background-color: $color--primary;
			border: 1px solid $color--primary;
			color: $color--secondary;
		}
	}